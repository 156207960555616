/* eslint-disable @typescript-eslint/no-explicit-any */
import ButtonLink from '@/components/ButtonLink'
import { Meta } from '@/layouts/Meta'
import { Main } from '@/templates/Main'
import { setContext } from '@/utils/api-interceptors'
import { type GetStaticProps, type GetStaticPropsContext } from 'next'

const NotFound = () => {
  return (
    <Main meta={<Meta title={'404 Not found'} description={'not found'} />}>
      <section className='notFoundPage m-auto px-[24px]'>
        <div className='flex flex-col items-center justify-center text-center'>
          <svg
            width='300'
            height='468'
            viewBox='0 0 500 468'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0_635_985)'>
              <path
                d='M71.9696 130.837L74.0779 135.089L78.7761 135.778L75.3794 139.094L76.1863 143.776L71.9696 141.565L67.766 143.776L68.5729 139.094L65.1631 135.778L69.8743 135.089L71.9696 130.837Z'
                fill='#EBEBEB'
              />
              <path
                d='M493.18 331.402L495.289 335.668L500 336.344L496.59 339.673L497.397 344.355L493.18 342.144L488.977 344.355L489.784 339.673L486.374 336.344L491.085 335.668L493.18 331.402Z'
                fill='#F5F5F5'
              />
              <path
                d='M409.758 455.061L411.854 459.313L416.565 460.002L413.155 463.318L413.962 468L409.758 465.789L405.542 468L406.348 463.318L402.952 460.002L407.65 459.313L409.758 455.061Z'
                fill='#F5F5F5'
              />
              <path
                d='M218.889 55.2814L219.67 56.868L221.427 57.128C221.525 57.1409 221.617 57.1813 221.693 57.2447C221.769 57.308 221.825 57.3917 221.855 57.4858C221.885 57.5799 221.888 57.6807 221.863 57.7762C221.838 57.8718 221.786 57.9582 221.713 58.0253L220.412 59.3258L220.711 61.0684C220.726 61.1653 220.715 61.2647 220.677 61.3554C220.64 61.4462 220.578 61.5248 220.498 61.5826C220.419 61.6405 220.325 61.6753 220.227 61.6832C220.129 61.6911 220.031 61.6719 219.943 61.6275L218.382 60.7953L216.82 61.6275C216.731 61.6704 216.633 61.6886 216.535 61.6803C216.437 61.6719 216.343 61.6373 216.263 61.58C216.183 61.5228 216.12 61.445 216.081 61.355C216.041 61.2649 216.027 61.1659 216.039 61.0684L216.338 59.3258L215.037 58.0253C214.964 57.9573 214.912 57.8699 214.888 57.7733C214.863 57.6768 214.867 57.5752 214.898 57.4807C214.93 57.3862 214.988 57.3027 215.065 57.2402C215.143 57.1777 215.237 57.1388 215.336 57.128L217.08 56.868L217.861 55.2814C217.892 55.1692 217.959 55.0703 218.052 54.9998C218.145 54.9293 218.258 54.8911 218.375 54.8911C218.492 54.8911 218.605 54.9293 218.698 54.9998C218.791 55.0703 218.858 55.1692 218.889 55.2814Z'
                fill='#EBEBEB'
              />
              <path
                d='M3.90434 434.826L4.69822 436.4L6.44215 436.66C6.54008 436.675 6.63173 436.718 6.70679 436.783C6.78186 436.847 6.83737 436.932 6.86711 437.026C6.89684 437.121 6.89962 437.222 6.87513 437.318C6.85064 437.414 6.79985 437.501 6.72847 437.57L5.42703 438.792L5.72636 440.535C5.74173 440.632 5.73 440.731 5.69246 440.822C5.65492 440.913 5.59303 440.991 5.51361 441.049C5.4342 441.107 5.34034 441.142 5.24239 441.15C5.14444 441.158 5.0462 441.138 4.95851 441.094L3.39678 440.275L1.83505 441.094C1.74736 441.138 1.64912 441.158 1.55117 441.15C1.45322 441.142 1.35937 441.107 1.27995 441.049C1.20053 440.991 1.13864 440.913 1.1011 440.822C1.06356 440.731 1.05183 440.632 1.0672 440.535L1.35352 438.792L0.052082 437.57C-0.0195415 437.5 -0.0701511 437.412 -0.0939824 437.315C-0.117814 437.218 -0.113909 437.116 -0.0827124 437.021C-0.051516 436.927 0.0057179 436.842 0.0824703 436.778C0.159223 436.714 0.252408 436.673 0.351413 436.66L2.09534 436.4L2.87621 434.826C2.89573 434.704 2.9582 434.593 3.05242 434.512C3.14664 434.432 3.26643 434.388 3.39027 434.388C3.51412 434.388 3.6339 434.432 3.72812 434.512C3.82234 434.593 3.88482 434.704 3.90434 434.826Z'
                fill='#F5F5F5'
              />
              <path
                d='M39.0433 38.5709L39.8241 40.1574L41.5811 40.4045C41.679 40.4201 41.7706 40.4627 41.8457 40.5274C41.9208 40.5922 41.9763 40.6766 42.006 40.7711C42.0358 40.8657 42.0385 40.9666 42.014 41.0626C41.9896 41.1587 41.9388 41.246 41.8674 41.3148L40.5659 42.5502L40.8653 44.2798C40.8861 44.3779 40.878 44.48 40.842 44.5737C40.8061 44.6674 40.7438 44.7487 40.6626 44.8078C40.5814 44.8669 40.4849 44.9013 40.3846 44.9068C40.2843 44.9124 40.1846 44.8888 40.0974 44.8389L38.5357 44.0197L36.974 44.8389C36.8859 44.8868 36.7861 44.909 36.686 44.9028C36.5859 44.8966 36.4896 44.8624 36.4081 44.804C36.3266 44.7456 36.2632 44.6655 36.2251 44.5728C36.1871 44.48 36.176 44.3785 36.1931 44.2798L36.4924 42.5502L35.191 41.3148C35.1194 41.2451 35.0688 41.1568 35.0449 41.0598C35.0211 40.9628 35.025 40.8611 35.0562 40.7662C35.0874 40.6713 35.1446 40.5871 35.2214 40.5232C35.2981 40.4592 35.3913 40.4181 35.4903 40.4045L37.2343 40.1574L38.0151 38.5709C38.0464 38.4586 38.1135 38.3597 38.2064 38.2892C38.2992 38.2187 38.4126 38.1806 38.5292 38.1806C38.6458 38.1806 38.7592 38.2187 38.852 38.2892C38.9448 38.3597 39.012 38.4586 39.0433 38.5709Z'
                fill='#EBEBEB'
              />
              <path
                d='M36.9347 352.001C36.9347 352.343 36.8332 352.678 36.643 352.962C36.4528 353.247 36.1825 353.468 35.8662 353.599C35.5499 353.73 35.2019 353.764 34.8661 353.698C34.5304 353.631 34.2219 353.466 33.9799 353.224C33.7378 352.982 33.5729 352.674 33.5062 352.339C33.4394 352.003 33.4736 351.656 33.6047 351.339C33.7357 351.023 33.9575 350.753 34.2422 350.563C34.5268 350.373 34.8615 350.272 35.2038 350.272C35.6618 350.275 36.1001 350.458 36.424 350.782C36.7479 351.106 36.9313 351.544 36.9347 352.001Z'
                fill='#EBEBEB'
              />
              <path
                d='M283.61 135.57C283.612 135.913 283.513 136.248 283.324 136.535C283.136 136.821 282.866 137.044 282.55 137.177C282.234 137.31 281.886 137.346 281.549 137.281C281.213 137.216 280.903 137.052 280.66 136.811C280.416 136.569 280.25 136.261 280.182 135.925C280.114 135.59 280.148 135.241 280.278 134.924C280.409 134.608 280.631 134.337 280.916 134.146C281.201 133.955 281.536 133.854 281.879 133.854C282.106 133.852 282.33 133.895 282.54 133.981C282.75 134.066 282.941 134.192 283.102 134.352C283.263 134.511 283.391 134.701 283.478 134.91C283.565 135.119 283.61 135.344 283.61 135.57Z'
                fill='#EBEBEB'
              />
              <path
                d='M193.914 40.0013C193.914 40.3434 193.813 40.6778 193.623 40.9622C193.433 41.2467 193.162 41.4683 192.846 41.5993C192.53 41.7302 192.182 41.7644 191.846 41.6977C191.51 41.6309 191.202 41.4662 190.96 41.2243C190.718 40.9824 190.553 40.6743 190.486 40.3388C190.419 40.0033 190.453 39.6555 190.584 39.3395C190.715 39.0234 190.937 38.7533 191.222 38.5632C191.507 38.3732 191.841 38.2718 192.184 38.2718C192.642 38.2752 193.08 38.4585 193.404 38.7821C193.728 39.1057 193.911 39.5437 193.914 40.0013Z'
                fill='#F5F5F5'
              />
              <path
                d='M196.599 461.521C201.741 460.309 204.925 455.161 203.712 450.023C202.498 444.886 197.346 441.704 192.205 442.916C187.063 444.129 183.879 449.277 185.092 454.414C186.306 459.552 191.458 462.734 196.599 461.521Z'
                fill='#F0F0F0'
              />
              <path
                d='M191.116 446.634C189.157 446.63 187.247 447.249 185.663 448.403C184.854 450.256 184.656 452.319 185.096 454.293C185.537 456.267 186.593 458.051 188.113 459.386C189.633 460.721 191.539 461.539 193.554 461.723C195.57 461.906 197.592 461.445 199.328 460.406C200.097 458.985 200.483 457.389 200.449 455.775C200.415 454.16 199.962 452.582 199.134 451.195C198.306 449.808 197.132 448.66 195.727 447.862C194.321 447.065 192.732 446.646 191.116 446.647V446.634Z'
                fill='#E6E6E6'
              />
              <path
                d='M117.038 286.095H37.8328V250.372L117.038 156.299H154.871V252.401H174.51V286.095H154.871V315.316H117.038V286.095ZM117.038 252.388V203.193L75.1841 252.401L117.038 252.388Z'
                fill='#407BFF'
              />
              <path
                d='M188.123 236.51C188.123 206.782 193.476 185.975 204.183 174.089C214.889 162.203 231.205 156.264 253.13 156.273C263.654 156.273 272.3 157.574 279.068 160.174C285.22 162.403 290.85 165.869 295.609 170.357C299.625 174.188 303.027 178.613 305.695 183.478C308.336 188.557 310.325 193.949 311.617 199.525C314.564 211.46 316.033 223.711 315.99 236.003C315.99 264.369 311.187 285.133 301.583 298.293C291.978 311.454 275.432 318.03 251.946 318.021C238.784 318.021 228.147 315.923 220.034 311.727C211.857 307.463 204.969 301.092 200.083 293.274C196.309 287.508 193.372 279.632 191.273 269.645C189.085 258.738 188.029 247.634 188.123 236.51ZM231.24 236.614C231.24 256.554 233.001 270.152 236.524 277.408C240.046 284.665 245.152 288.31 251.842 288.345C253.967 288.369 256.076 287.971 258.047 287.174C260.017 286.377 261.81 285.197 263.32 283.703C266.557 280.608 268.938 275.718 270.465 269.034C271.992 262.349 272.76 251.946 272.769 237.823C272.769 217.068 271.007 203.115 267.485 195.962C263.962 188.81 258.679 185.238 251.633 185.247C244.449 185.247 239.244 188.892 236.016 196.183C232.789 203.474 231.196 216.973 231.24 236.679V236.614Z'
                fill='#407BFF'
              />
              <path
                d='M408.652 286.095H329.446V250.372L408.652 156.299H446.55V252.401H466.176V286.095H446.537V315.316H408.652V286.095ZM408.652 252.388V203.193L366.811 252.401L408.652 252.388Z'
                fill='#407BFF'
              />
              <g opacity='0.3'>
                <path
                  d='M186.366 101.434C186.366 101.915 186.223 102.385 185.956 102.785C185.688 103.185 185.308 103.496 184.864 103.681C184.419 103.865 183.93 103.913 183.457 103.819C182.985 103.725 182.552 103.493 182.211 103.153C181.871 102.813 181.639 102.38 181.545 101.908C181.451 101.437 181.5 100.948 181.684 100.503C181.868 100.059 182.18 99.679 182.58 99.4118C182.98 99.1446 183.451 99.002 183.932 99.002C184.578 99.002 185.197 99.2582 185.653 99.7143C186.11 100.17 186.366 100.789 186.366 101.434Z'
                  fill='#407BFF'
                />
                <path
                  d='M19.7948 194.818C20.7436 194.818 21.5127 194.049 21.5127 193.101C21.5127 192.153 20.7436 191.385 19.7948 191.385C18.846 191.385 18.0769 192.153 18.0769 193.101C18.0769 194.049 18.846 194.818 19.7948 194.818Z'
                  fill='#407BFF'
                />
                <path
                  d='M303.626 445.502C304.984 445.502 306.085 444.402 306.085 443.045C306.085 441.687 304.984 440.587 303.626 440.587C302.267 440.587 301.166 441.687 301.166 443.045C301.166 444.402 302.267 445.502 303.626 445.502Z'
                  fill='#407BFF'
                />
                <path
                  d='M362.776 345.499C363.725 345.499 364.494 344.731 364.494 343.783C364.494 342.835 363.725 342.066 362.776 342.066C361.827 342.066 361.058 342.835 361.058 343.783C361.058 344.731 361.827 345.499 362.776 345.499Z'
                  fill='#407BFF'
                />
                <path
                  d='M476.873 36.4121C476.873 36.7516 476.773 37.0835 476.584 37.3658C476.395 37.6481 476.127 37.8681 475.813 37.998C475.499 38.128 475.153 38.162 474.82 38.0957C474.487 38.0295 474.181 37.866 473.941 37.6259C473.7 37.3859 473.537 37.08 473.471 36.747C473.404 36.414 473.438 36.0689 473.568 35.7552C473.698 35.4416 473.918 35.1735 474.201 34.9849C474.483 34.7962 474.816 34.6956 475.155 34.6956C475.611 34.6956 476.048 34.8764 476.37 35.1983C476.692 35.5202 476.873 35.9569 476.873 36.4121Z'
                  fill='#407BFF'
                />
                <path
                  d='M149.666 1.71657C149.666 2.05608 149.565 2.38796 149.376 2.67025C149.187 2.95254 148.919 3.17255 148.605 3.30248C148.291 3.4324 147.946 3.4664 147.612 3.40016C147.279 3.33393 146.973 3.17044 146.733 2.93037C146.493 2.6903 146.329 2.38444 146.263 2.05146C146.196 1.71848 146.23 1.37333 146.361 1.05967C146.491 0.746006 146.711 0.477914 146.993 0.289295C147.276 0.100675 147.608 0 147.948 0C148.402 0.00340248 148.837 0.185348 149.159 0.506533C149.48 0.827718 149.662 1.26236 149.666 1.71657Z'
                  fill='#407BFF'
                />
                <path
                  d='M286.238 91.8366C287.187 91.8366 287.956 91.0681 287.956 90.12C287.956 89.172 287.187 88.4035 286.238 88.4035C285.29 88.4035 284.521 89.172 284.521 90.12C284.521 91.0681 285.29 91.8366 286.238 91.8366Z'
                  fill='#407BFF'
                />
              </g>
              <path
                d='M108.644 91.8496C124.392 91.8496 137.159 79.0931 137.159 63.3571C137.159 47.6212 124.392 34.8646 108.644 34.8646C92.896 34.8646 80.1296 47.6212 80.1296 63.3571C80.1296 79.0931 92.896 91.8496 108.644 91.8496Z'
                fill='#407BFF'
              />
              <path
                opacity='0.7'
                d='M108.644 91.8496C124.392 91.8496 137.159 79.0931 137.159 63.3571C137.159 47.6212 124.392 34.8646 108.644 34.8646C92.896 34.8646 80.1296 47.6212 80.1296 63.3571C80.1296 79.0931 92.896 91.8496 108.644 91.8496Z'
                fill='white'
              />
              <path
                opacity='0.2'
                d='M98.8184 41.8609C94.9496 41.8784 91.1245 42.6791 87.574 44.2147C83.219 49.0364 80.6424 55.1995 80.2708 61.6839C79.8992 68.1683 81.7549 74.585 85.5307 79.8718C89.3064 85.1587 94.7758 88.9986 101.033 90.7558C107.291 92.5131 113.962 92.0823 119.941 89.5348C123.657 85.4475 126.105 80.3696 126.988 74.9181C127.87 69.4667 127.149 63.8765 124.912 58.8269C122.674 53.7773 119.017 49.4858 114.385 46.4739C109.753 43.462 104.345 41.8594 98.8184 41.8609Z'
                fill='#407BFF'
              />
              <path
                opacity='0.2'
                d='M95.9811 49.4165C95.9811 49.9309 95.8285 50.4337 95.5425 50.8614C95.2565 51.2891 94.85 51.6225 94.3743 51.8194C93.8987 52.0162 93.3754 52.0677 92.8705 51.9674C92.3656 51.867 91.9018 51.6193 91.5378 51.2556C91.1737 50.8918 90.9258 50.4284 90.8254 49.9239C90.725 49.4194 90.7765 48.8964 90.9735 48.4212C91.1705 47.9459 91.5042 47.5397 91.9322 47.2539C92.3602 46.9681 92.8635 46.8156 93.3783 46.8156C94.0686 46.8156 94.7307 47.0896 95.2188 47.5774C95.7069 48.0651 95.9811 48.7267 95.9811 49.4165Z'
                fill='#407BFF'
              />
              <path
                opacity='0.2'
                d='M126.643 46.8156C126.643 47.33 126.49 47.8329 126.204 48.2606C125.918 48.6883 125.512 49.0216 125.036 49.2185C124.561 49.4153 124.037 49.4669 123.532 49.3665C123.027 49.2661 122.564 49.0184 122.2 48.6547C121.836 48.291 121.588 47.8275 121.487 47.323C121.387 46.8185 121.438 46.2955 121.635 45.8203C121.832 45.3451 122.166 44.9388 122.594 44.6531C123.022 44.3673 123.525 44.2147 124.04 44.2147C124.73 44.2147 125.393 44.4888 125.881 44.9765C126.369 45.4643 126.643 46.1258 126.643 46.8156Z'
                fill='#407BFF'
              />
              <path
                opacity='0.2'
                d='M121.437 65.3598C121.442 66.2028 121.197 67.0285 120.733 67.7325C120.269 68.4364 119.606 68.9872 118.829 69.3151C118.052 69.643 117.195 69.7335 116.366 69.575C115.537 69.4165 114.774 69.0162 114.173 68.4246C113.572 67.833 113.16 67.0767 112.989 66.2512C112.818 65.4257 112.895 64.568 113.211 63.7864C113.528 63.0049 114.069 62.3345 114.766 61.86C115.463 61.3855 116.286 61.1281 117.13 61.1204C117.691 61.1152 118.248 61.2209 118.769 61.4313C119.29 61.6418 119.764 61.9528 120.164 62.3466C120.564 62.7405 120.883 63.2094 121.101 63.7264C121.32 64.2435 121.434 64.7985 121.437 65.3598Z'
                fill='#407BFF'
              />
              <path
                opacity='0.2'
                d='M107.876 77.5188C107.876 78.6454 107.542 79.7466 106.916 80.6833C106.289 81.62 105.399 82.3501 104.357 82.7812C103.316 83.2123 102.17 83.3251 101.064 83.1053C99.9581 82.8855 98.9424 82.343 98.1452 81.5464C97.348 80.7499 96.8051 79.7349 96.5851 78.6301C96.3652 77.5252 96.4781 76.3799 96.9095 75.3391C97.3409 74.2983 98.0716 73.4087 99.009 72.7829C99.9464 72.157 101.048 71.8229 102.176 71.8229C103.688 71.8229 105.138 72.423 106.207 73.4912C107.276 74.5594 107.876 76.0082 107.876 77.5188Z'
                fill='#407BFF'
              />
              <path
                d='M446.592 407.041C449.767 393.599 441.436 380.131 427.984 376.958C414.532 373.786 401.053 382.11 397.878 395.552C394.703 408.994 403.034 422.463 416.486 425.635C429.938 428.808 443.417 420.483 446.592 407.041Z'
                fill='#407BFF'
              />
              <path
                opacity='0.3'
                d='M446.592 407.041C449.767 393.599 441.436 380.131 427.984 376.958C414.532 373.786 401.053 382.11 397.878 395.552C394.703 408.994 403.034 422.463 416.486 425.635C429.938 428.808 443.417 420.483 446.592 407.041Z'
                fill='white'
              />
              <path
                opacity='0.4'
                d='M438.039 381.937C432.499 380.29 426.56 380.609 421.229 382.841C415.899 385.072 411.505 389.078 408.795 394.179C406.085 399.28 405.225 405.16 406.361 410.823C407.498 416.485 410.56 421.58 415.029 425.242C420.575 426.904 426.525 426.593 431.867 424.362C437.209 422.132 441.612 418.12 444.326 413.01C447.039 407.9 447.896 402.007 446.749 396.337C445.603 390.666 442.525 385.568 438.039 381.911V381.937Z'
                fill='#407BFF'
              />
              <path
                d='M398.345 408.739C371.613 421.301 391.551 430.99 429.202 417.465C464.054 404.942 477.355 389.155 446.121 394.057C447.799 400.689 404.136 417.686 398.345 408.739Z'
                fill='#407BFF'
              />
              <path
                opacity='0.2'
                d='M437.739 156.299C402.743 179.928 357.44 211.086 344.881 286.095H342.279C354.447 211.971 398.097 180.019 433.106 156.299H437.739Z'
                fill='black'
              />
              <path
                d='M256.735 390.949C234.61 390.949 212.733 380.975 202.074 364.694C195.502 354.628 187.863 333.548 210.612 305.42L212.629 307.058C196.543 326.955 193.563 346.917 204.261 363.276C217.275 383.186 247.976 393.186 274.187 386.073C301.023 378.764 317.33 355.434 318.905 322.052C324.28 207.861 384.992 172.685 429.319 147.001C456.883 131.045 476.782 119.523 472.422 95.1917C472.275 93.7821 471.833 92.4192 471.125 91.1914C470.416 89.9637 469.457 88.8986 468.31 88.0653C462.649 84.3331 451.586 86.921 438.78 89.8989C413.649 95.7509 379.239 103.762 358.742 68.923L360.98 67.6225C380.502 100.823 412.491 93.3711 438.182 87.3891C451.573 84.2811 463.143 81.5892 469.741 85.9196C472.54 87.7532 474.244 90.6532 474.947 94.7626C479.645 120.875 458.028 133.411 430.633 149.277C386.814 174.661 326.817 209.422 321.494 322.234C319.868 356.826 302.871 381.027 274.864 388.635C268.951 390.207 262.854 390.986 256.735 390.949Z'
                fill='#407BFF'
              />
              <path
                opacity='0.2'
                d='M256.735 390.949C234.61 390.949 212.733 380.975 202.074 364.694C195.502 354.628 187.863 333.548 210.612 305.42L212.629 307.058C196.543 326.955 193.563 346.917 204.261 363.276C217.275 383.186 247.976 393.186 274.187 386.073C301.023 378.764 317.33 355.434 318.905 322.052C324.28 207.861 384.992 172.685 429.319 147.001C456.883 131.045 476.782 119.523 472.422 95.1917C472.275 93.7821 471.833 92.4192 471.125 91.1914C470.416 89.9637 469.457 88.8986 468.31 88.0653C462.649 84.3331 451.586 86.921 438.78 89.8989C413.649 95.7509 379.239 103.762 358.742 68.923L360.98 67.6225C380.502 100.823 412.491 93.3711 438.182 87.3891C451.573 84.2811 463.143 81.5892 469.741 85.9196C472.54 87.7532 474.244 90.6532 474.947 94.7626C479.645 120.875 458.028 133.411 430.633 149.277C386.814 174.661 326.817 209.422 321.494 322.234C319.868 356.826 302.871 381.027 274.864 388.635C268.951 390.207 262.854 390.986 256.735 390.949Z'
                fill='white'
              />
              <path
                d='M331.867 38.1157C337.864 38.1503 343.821 39.084 349.54 40.8856C349.54 40.8856 363.856 65.2948 365.548 70.8866C364.95 76.4005 355.631 85.4385 355.631 85.4385L331.867 38.1157Z'
                fill='#407BFF'
              />
              <path
                opacity='0.3'
                d='M331.867 38.1157C337.864 38.1503 343.821 39.084 349.54 40.8856C349.54 40.8856 363.856 65.2948 365.548 70.8866C364.95 76.4005 355.631 85.4385 355.631 85.4385L331.867 38.1157Z'
                fill='white'
              />
              <path
                d='M374.281 156.689C368.755 149.955 363.714 142.836 359.197 135.388C358.104 133.507 357.067 131.591 356.087 129.64C355.527 128.535 354.994 127.429 354.473 126.311C353.785 125.226 353.254 124.048 352.898 122.813C351.337 106.336 356.985 94.2034 351.597 80.978L330.162 89.3528C330.162 89.3528 331.984 112.917 336.149 127.065C338.752 135.817 344.582 143.386 350.204 150.395C351.974 152.58 353.666 154.83 355.41 157.027C357.154 159.225 358.95 161.007 360.616 163.074C363.154 166.208 363.987 169.212 362.06 172.906L361.748 173.491C361.201 174.453 363.752 175.546 365.366 173.491C367.969 170.136 367.605 170.344 369.869 167.483C371.249 165.753 372.823 163.868 374.19 162.281C374.85 161.503 375.22 160.521 375.236 159.501C375.253 158.482 374.915 157.488 374.281 156.689Z'
                fill='#407BFF'
              />
              <path
                opacity='0.7'
                d='M374.281 156.689C368.755 149.955 363.714 142.836 359.197 135.388C358.104 133.507 357.067 131.591 356.087 129.64C355.527 128.535 354.994 127.429 354.473 126.311C353.785 125.226 353.254 124.048 352.898 122.813C351.337 106.336 356.985 94.2034 351.597 80.978L330.162 89.3528C330.162 89.3528 331.984 112.917 336.149 127.065C338.752 135.817 344.582 143.386 350.204 150.395C351.974 152.58 353.666 154.83 355.41 157.027C357.154 159.225 358.95 161.007 360.616 163.074C363.154 166.208 363.987 169.212 362.06 172.906L361.748 173.491C361.201 174.453 363.752 175.546 365.366 173.491C367.969 170.136 367.605 170.344 369.869 167.483C371.249 165.753 372.823 163.868 374.19 162.281C374.85 161.503 375.22 160.521 375.236 159.501C375.253 158.482 374.915 157.488 374.281 156.689Z'
                fill='white'
              />
              <path
                opacity='0.3'
                d='M369.036 149.914C365.63 153.556 361.852 156.834 357.766 159.693C358.325 160.317 358.872 160.994 359.431 161.592C363.526 158.828 367.244 155.545 370.494 151.826L369.036 149.914Z'
                fill='#407BFF'
              />
              <path
                opacity='0.3'
                d='M374.268 156.702C374.228 156.632 374.18 156.567 374.125 156.507C373.112 157.648 372.528 159.106 372.472 160.63C372.388 161.673 372.558 162.722 372.967 163.686L374.203 162.216C374.849 161.446 375.208 160.476 375.22 159.471C375.231 158.466 374.895 157.488 374.268 156.702Z'
                fill='#407BFF'
              />
              <path
                d='M326.778 53.7209C324.453 57.1997 321.664 60.3454 318.488 63.071C316.725 64.5849 314.795 65.8937 312.736 66.9723C311.652 67.5244 310.53 67.9981 309.378 68.3898L308.493 68.6759L308.207 68.7539C308.007 68.8223 307.802 68.8787 307.595 68.923C307.218 69.0113 306.836 69.0721 306.45 69.105C305.352 69.1889 304.248 69.0878 303.183 68.8059C301.831 68.4192 300.535 67.8551 299.331 67.1284C298.368 66.5655 297.434 65.9535 296.533 65.2948C294.904 64.0644 293.348 62.74 291.874 61.3284C289.03 58.6347 286.418 55.7071 284.065 52.5765C283.604 51.966 283.373 51.2131 283.411 50.4493C283.448 49.6855 283.753 48.9592 284.273 48.3972C284.792 47.8352 285.492 47.4732 286.251 47.3742C287.009 47.2753 287.779 47.4458 288.425 47.8559L288.529 47.921C291.601 49.8716 294.698 51.9263 297.717 53.7599C299.253 54.6572 300.737 55.5675 302.207 56.2698C302.874 56.6208 303.561 56.9334 304.264 57.2061C304.709 57.3995 305.184 57.514 305.669 57.5442C305.669 57.5442 305.669 57.4531 305.175 57.4662C305.026 57.4788 304.878 57.5005 304.732 57.5312H304.446L304.875 57.3101C305.456 57.004 306.017 56.6609 306.554 56.2828C307.716 55.4592 308.805 54.5368 309.808 53.5258C312.102 51.1594 314.254 48.6581 316.25 46.0353C317.286 44.6818 318.812 43.7887 320.501 43.5484C322.189 43.3082 323.905 43.7398 325.278 44.7506C326.651 45.7613 327.572 47.2703 327.843 48.9528C328.114 50.6353 327.713 52.3568 326.726 53.7469L326.778 53.7209Z'
                fill='#407BFF'
              />
              <path
                opacity='0.7'
                d='M326.778 53.7209C324.453 57.1997 321.664 60.3454 318.488 63.071C316.725 64.5849 314.795 65.8937 312.736 66.9723C311.652 67.5244 310.53 67.9981 309.378 68.3898L308.493 68.6759L308.207 68.7539C308.007 68.8223 307.802 68.8787 307.595 68.923C307.218 69.0113 306.836 69.0721 306.45 69.105C305.352 69.1889 304.248 69.0878 303.183 68.8059C301.831 68.4192 300.535 67.8551 299.331 67.1284C298.368 66.5655 297.434 65.9535 296.533 65.2948C294.904 64.0644 293.348 62.74 291.874 61.3284C289.03 58.6347 286.418 55.7071 284.065 52.5765C283.604 51.966 283.373 51.2131 283.411 50.4493C283.448 49.6855 283.753 48.9592 284.273 48.3972C284.792 47.8352 285.492 47.4732 286.251 47.3742C287.009 47.2753 287.779 47.4458 288.425 47.8559L288.529 47.921C291.601 49.8716 294.698 51.9263 297.717 53.7599C299.253 54.6572 300.737 55.5675 302.207 56.2698C302.874 56.6208 303.561 56.9334 304.264 57.2061C304.709 57.3995 305.184 57.514 305.669 57.5442C305.669 57.5442 305.669 57.4531 305.175 57.4662C305.026 57.4788 304.878 57.5005 304.732 57.5312H304.446L304.875 57.3101C305.456 57.004 306.017 56.6609 306.554 56.2828C307.716 55.4592 308.805 54.5368 309.808 53.5258C312.102 51.1594 314.254 48.6581 316.25 46.0353C317.286 44.6818 318.812 43.7887 320.501 43.5484C322.189 43.3082 323.905 43.7398 325.278 44.7506C326.651 45.7613 327.572 47.2703 327.843 48.9528C328.114 50.6353 327.713 52.3568 326.726 53.7469L326.778 53.7209Z'
                fill='white'
              />
              <path
                d='M279.198 45.2291L280.72 47.8299C280.72 47.8299 281.892 51.2371 284.221 51.8613L290.546 49.8196L290.221 49.2864C289.414 48.064 289.492 45.6842 289.778 43.7076C290.065 41.7309 289.037 41.6659 288.282 42.16C287.839 42.4331 287.54 43.3434 287.176 44.3058C286.874 43.8735 286.534 43.4687 286.16 43.0964L284.234 41.1717C283.83 40.7771 283.292 40.5497 282.727 40.5352C282.162 40.5207 281.613 40.7203 281.189 41.0937L279.627 42.4852C279.253 42.8246 279.006 43.282 278.927 43.7813C278.849 44.2806 278.945 44.7915 279.198 45.2291Z'
                fill='#407BFF'
              />
              <path
                opacity='0.7'
                d='M279.198 45.2291L280.72 47.8299C280.72 47.8299 281.892 51.2371 284.221 51.8613L290.546 49.8196L290.221 49.2864C289.414 48.064 289.492 45.6842 289.778 43.7076C290.065 41.7309 289.037 41.6659 288.282 42.16C287.839 42.4331 287.54 43.3434 287.176 44.3058C286.874 43.8735 286.534 43.4687 286.16 43.0964L284.234 41.1717C283.83 40.7771 283.292 40.5497 282.727 40.5352C282.162 40.5207 281.613 40.7203 281.189 41.0937L279.627 42.4852C279.253 42.8246 279.006 43.282 278.927 43.7813C278.849 44.2806 278.945 44.7915 279.198 45.2291Z'
                fill='white'
              />
              <path
                d='M338.27 35.866C331.25 37.7241 324.526 40.5562 318.293 44.2798C317.324 44.8892 316.564 45.7799 316.115 46.8326C315.666 47.8854 315.55 49.05 315.781 50.1707C318.293 62.4208 324.058 78.8843 330.162 89.3528L358.95 77.4538C359.145 72.3821 352.156 55.9707 345.038 40.1054C343.762 37.2574 341.315 35.1117 338.27 35.866Z'
                fill='#407BFF'
              />
              <path
                opacity='0.8'
                d='M338.27 35.866C331.25 37.7241 324.526 40.5562 318.293 44.2798C317.324 44.8892 316.564 45.7799 316.115 46.8326C315.666 47.8854 315.55 49.05 315.781 50.1707C318.293 62.4208 324.058 78.8843 330.162 89.3528L358.95 77.4538C359.145 72.3821 352.156 55.9707 345.038 40.1054C343.762 37.2574 341.315 35.1117 338.27 35.866Z'
                fill='white'
              />
              <path
                opacity='0.3'
                d='M349.488 50.1577L343.788 48.2461C345.09 51.5882 349.684 55.8146 352.898 58.3114C351.857 55.6976 350.699 52.9667 349.488 50.1577Z'
                fill='#407BFF'
              />
              <path
                d='M336.383 22.9917C334.001 18.4662 328.861 16.1904 322.692 16.6976C317.486 17.1397 312.879 22.4455 313.426 25.3064C313.972 28.1674 318.345 29.3898 319.139 30.3911L315.534 32.992C315.107 33.3 314.747 33.6912 314.475 34.1419C314.203 34.5927 314.025 35.0938 313.952 35.615C313.879 36.1362 313.913 36.6667 314.05 37.1747C314.188 37.6827 314.427 38.1576 314.753 38.5709C316.276 40.4955 318.28 42.4722 319.438 43.9286C329.407 43.6686 336.786 39.8713 339.441 36.2171C338.491 31.5745 338.739 27.4912 336.383 22.9917Z'
                fill='#407BFF'
              />
              <path
                opacity='0.8'
                d='M336.383 22.9917C334.001 18.4662 328.861 16.1904 322.692 16.6976C317.486 17.1397 312.879 22.4455 313.426 25.3064C313.972 28.1674 318.345 29.3898 319.139 30.3911L315.534 32.992C315.107 33.3 314.747 33.6912 314.475 34.1419C314.203 34.5927 314.025 35.0938 313.952 35.615C313.879 36.1362 313.913 36.6667 314.05 37.1747C314.188 37.6827 314.427 38.1576 314.753 38.5709C316.276 40.4955 318.28 42.4722 319.438 43.9286C329.407 43.6686 336.786 39.8713 339.441 36.2171C338.491 31.5745 338.739 27.4912 336.383 22.9917Z'
                fill='white'
              />
              <path
                d='M331.49 25.8006C332.197 27.6142 332.351 29.5967 331.931 31.4976C331.512 33.3985 330.538 35.1324 329.132 36.4801C327.726 37.8278 325.952 38.7289 324.034 39.0693C322.116 39.4098 320.14 39.1745 318.356 38.393C316.572 37.6115 315.059 36.319 314.01 34.6789C312.961 33.0388 312.422 31.1246 312.461 29.1784C312.5 27.2323 313.116 25.3414 314.231 23.7448C315.345 22.1483 316.908 20.9177 318.723 20.2087C321.158 19.2608 323.87 19.3172 326.264 20.3656C328.658 21.414 330.537 23.3687 331.49 25.8006Z'
                fill='#263238'
              />
              <path
                d='M415.992 143.048C415.836 138.769 415.654 139.146 415.537 135.544C415.459 133.333 415.446 130.876 415.432 128.756C415.423 127.751 415.069 126.779 414.43 126.003C413.791 125.226 412.904 124.693 411.919 124.491C410.201 124.139 408.47 123.814 406.713 123.45C404.474 122.969 402.249 122.449 400.05 121.812C398.332 121.317 396.64 120.771 394.961 120.16C393.282 119.549 391.395 118.86 389.638 118.053C387.595 117.182 385.564 116.246 383.56 115.283C381.309 114.204 379.083 113.086 376.858 111.954C368.307 97.7406 368.138 88.4295 358.95 77.4538L339.22 86.3748C339.22 86.3748 353.718 112.071 363.583 122.969C369.27 129.25 378.185 132.072 386.137 134.127C391.876 135.596 397.694 136.728 403.511 137.807C405.776 138.223 408.236 138.379 410.188 139.732C411.632 140.838 412.622 142.434 412.973 144.218C413.06 144.582 413.138 144.946 413.207 145.31C413.415 146.403 416.07 145.649 415.992 143.048Z'
                fill='#407BFF'
              />
              <path
                opacity='0.8'
                d='M415.992 143.048C415.836 138.769 415.654 139.146 415.537 135.544C415.459 133.333 415.446 130.876 415.432 128.756C415.423 127.751 415.069 126.779 414.43 126.003C413.791 125.226 412.904 124.693 411.919 124.491C410.201 124.139 408.47 123.814 406.713 123.45C404.474 122.969 402.249 122.449 400.05 121.812C398.332 121.317 396.64 120.771 394.961 120.16C393.282 119.549 391.395 118.86 389.638 118.053C387.595 117.182 385.564 116.246 383.56 115.283C381.309 114.204 379.083 113.086 376.858 111.954C368.307 97.7406 368.138 88.4295 358.95 77.4538L339.22 86.3748C339.22 86.3748 353.718 112.071 363.583 122.969C369.27 129.25 378.185 132.072 386.137 134.127C391.876 135.596 397.694 136.728 403.511 137.807C405.776 138.223 408.236 138.379 410.188 139.732C411.632 140.838 412.622 142.434 412.973 144.218C413.06 144.582 413.138 144.946 413.207 145.31C413.415 146.403 416.07 145.649 415.992 143.048Z'
                fill='white'
              />
              <path
                opacity='0.3'
                d='M406.049 123.229C405.268 123.06 404.487 122.891 403.693 122.696C403.759 127.195 401.65 134.946 400.882 137.3L403.355 137.768C404.976 133.084 405.884 128.183 406.049 123.229Z'
                fill='#407BFF'
              />
              <path
                opacity='0.3'
                d='M415.432 128.743C415.428 127.736 415.076 126.762 414.436 125.985C413.795 125.208 412.906 124.675 411.919 124.477L410.682 124.23C410.891 125.709 411.648 127.055 412.803 128.002C413.544 128.691 414.435 129.199 415.406 129.484C415.432 129.185 415.432 128.951 415.432 128.743Z'
                fill='#407BFF'
              />
              <path
                d='M329.642 25.8786C330.175 27.8553 327.963 29.923 326.193 28.2194C324.558 26.4586 322.768 24.8482 320.844 23.4078C319.022 22.2764 321.442 20.2998 324.293 21.067C325.509 21.3724 326.633 21.9705 327.565 22.8091C328.497 23.6478 329.21 24.7016 329.642 25.8786Z'
                fill='white'
              />
              <path
                d='M329.798 88.7156C328.874 89.0537 330.552 90.6142 330.552 90.6142C330.552 90.6142 348.773 84.3851 359.835 77.974C359.862 77.5859 359.795 77.197 359.641 76.8396C359.487 76.4822 359.25 76.1667 358.95 75.9193C349.482 80.7317 339.749 85.0039 329.798 88.7156Z'
                fill='#407BFF'
              />
              <path
                opacity='0.5'
                d='M329.798 88.7156C328.874 89.0537 330.552 90.6142 330.552 90.6142C330.552 90.6142 348.773 84.3851 359.835 77.974C359.862 77.5859 359.795 77.197 359.641 76.8396C359.487 76.4822 359.25 76.1667 358.95 75.9193C349.482 80.7317 339.749 85.0039 329.798 88.7156Z'
                fill='white'
              />
              <path
                d='M343.203 35.0077C346.795 36.8283 350.165 38.7399 353.614 40.8596C355.293 41.926 356.959 43.0053 358.612 44.1757C360.264 45.3461 361.904 46.5425 363.557 47.895L364.169 48.4021L364.937 49.1044C365.396 49.5345 365.831 49.9904 366.238 50.4698C366.655 50.925 367.006 51.3671 367.344 51.7703C367.683 52.1734 368.034 52.6546 368.346 53.0707C369.559 54.7425 370.68 56.479 371.704 58.2724C373.721 61.7502 375.462 65.3809 376.91 69.131C377.178 69.8396 377.191 70.6189 376.949 71.3365C376.707 72.0541 376.223 72.6656 375.58 73.0671C374.938 73.4687 374.176 73.6354 373.424 73.539C372.672 73.4425 371.977 73.0889 371.457 72.5382L371.392 72.4602C368.789 69.7032 366.264 66.7773 363.752 64.0724C361.241 61.3675 358.651 58.5845 356.542 57.3751C353.588 55.5415 350.295 53.7079 347.029 51.9133L337.19 46.4515C335.664 45.6444 334.522 44.2648 334.014 42.6162C333.507 40.9676 333.675 39.185 334.483 37.6606C335.291 36.1361 336.671 34.9947 338.321 34.4874C339.971 33.9802 341.755 34.1486 343.281 34.9556L343.203 35.0077Z'
                fill='#407BFF'
              />
              <path
                opacity='0.8'
                d='M343.203 35.0077C346.795 36.8283 350.165 38.7399 353.614 40.8596C355.293 41.926 356.959 43.0053 358.612 44.1757C360.264 45.3461 361.904 46.5425 363.557 47.895L364.169 48.4021L364.937 49.1044C365.396 49.5345 365.831 49.9904 366.238 50.4698C366.655 50.925 367.006 51.3671 367.344 51.7703C367.683 52.1734 368.034 52.6546 368.346 53.0707C369.559 54.7425 370.68 56.479 371.704 58.2724C373.721 61.7502 375.462 65.3809 376.91 69.131C377.178 69.8396 377.191 70.6189 376.949 71.3365C376.707 72.0541 376.223 72.6656 375.58 73.0671C374.938 73.4687 374.176 73.6354 373.424 73.539C372.672 73.4425 371.977 73.0889 371.457 72.5382L371.392 72.4602C368.789 69.7032 366.264 66.7773 363.752 64.0724C361.241 61.3675 358.651 58.5845 356.542 57.3751C353.588 55.5415 350.295 53.7079 347.029 51.9133L337.19 46.4515C335.664 45.6444 334.522 44.2648 334.014 42.6162C333.507 40.9676 333.675 39.185 334.483 37.6606C335.291 36.1361 336.671 34.9947 338.321 34.4874C339.971 33.9802 341.755 34.1486 343.281 34.9556L343.203 35.0077Z'
                fill='white'
              />
              <path
                d='M380.02 75.5552L378.888 72.8243C378.888 72.8243 378.276 69.2871 376.077 68.2988L369.57 69.3261L369.791 69.8983C370.403 71.1987 369.947 73.5785 369.348 75.4772C368.75 77.3758 369.765 77.6229 370.585 77.2458C371.066 77.0377 371.509 76.1924 372.016 75.2951C372.254 75.7498 372.529 76.1847 372.836 76.5955L374.437 78.8063C374.779 79.2498 375.274 79.5493 375.826 79.6456C376.378 79.7419 376.945 79.628 377.417 79.3264L379.187 78.1951C379.606 77.9155 379.917 77.5015 380.068 77.0215C380.22 76.5415 380.203 76.0242 380.02 75.5552Z'
                fill='#407BFF'
              />
              <path
                opacity='0.8'
                d='M380.02 75.5552L378.888 72.8243C378.888 72.8243 378.276 69.2871 376.077 68.2988L369.57 69.3261L369.791 69.8983C370.403 71.1987 369.947 73.5785 369.348 75.4772C368.75 77.3758 369.765 77.6229 370.585 77.2458C371.066 77.0377 371.509 76.1924 372.016 75.2951C372.254 75.7498 372.529 76.1847 372.836 76.5955L374.437 78.8063C374.779 79.2498 375.274 79.5493 375.826 79.6456C376.378 79.7419 376.945 79.628 377.417 79.3264L379.187 78.1951C379.606 77.9155 379.917 77.5015 380.068 77.0215C380.22 76.5415 380.203 76.0242 380.02 75.5552Z'
                fill='white'
              />
              <path
                opacity='0.3'
                d='M337.698 49.9626L336.123 50.0927L326.375 73.6045C326.908 73.6428 327.443 73.599 327.963 73.4745C327.963 73.4745 340.665 68.7409 344.504 66.9723C341.914 61.6535 337.698 49.9626 337.698 49.9626Z'
                fill='#407BFF'
              />
              <path
                d='M319.92 56.1267C321.104 62.2728 323.303 68.1793 326.427 73.6045C331.19 71.966 339.129 68.8709 342.968 67.1024C340.414 61.5392 338.146 55.8488 336.175 50.0537C332.023 50.3658 323.082 53.7209 319.92 56.1267Z'
                fill='white'
              />
              <path
                opacity='0.3'
                d='M329.589 61.2114C329.399 61.8473 329.024 62.4126 328.513 62.8357C328.001 63.2587 327.375 63.5206 326.714 63.5881C326.053 63.6556 325.387 63.5258 324.8 63.215C324.213 62.9042 323.732 62.4265 323.417 61.8422C323.101 61.2579 322.967 60.5933 323.03 59.9325C323.093 59.2718 323.35 58.6445 323.77 58.1301C324.19 57.6157 324.753 57.2373 325.388 57.0427C326.023 56.8481 326.702 56.8461 327.338 57.037C328.19 57.2927 328.906 57.8759 329.328 58.6587C329.75 59.4414 329.844 60.3595 329.589 61.2114Z'
                fill='#407BFF'
              />
              <path
                opacity='0.6'
                d='M332.075 56.7379C331.993 57.0038 331.813 57.2285 331.571 57.3663C331.33 57.504 331.044 57.5444 330.774 57.4792C330.494 57.392 330.26 57.1989 330.121 56.9412C329.983 56.6835 329.951 56.3818 330.032 56.1007C330.119 55.8248 330.311 55.5939 330.566 55.4578C330.822 55.3216 331.121 55.291 331.398 55.3725C331.669 55.4643 331.892 55.6595 332.019 55.9155C332.146 56.1714 332.166 56.4672 332.075 56.7379Z'
                fill='#407BFF'
              />
              <path
                opacity='0.6'
                d='M334.977 55.6065C334.937 55.7453 334.87 55.8747 334.779 55.9873C334.688 56.0998 334.576 56.1933 334.449 56.2622C334.322 56.3312 334.182 56.3742 334.039 56.3889C333.895 56.4036 333.749 56.3896 333.611 56.3478C333.46 56.3173 333.317 56.2556 333.191 56.1666C333.066 56.0777 332.96 55.9636 332.881 55.8315C332.802 55.6993 332.751 55.5522 332.732 55.3994C332.714 55.2467 332.727 55.0917 332.771 54.9443C332.816 54.797 332.891 54.6606 332.991 54.5439C333.092 54.4272 333.215 54.3327 333.355 54.2665C333.494 54.2003 333.645 54.1638 333.799 54.1594C333.953 54.1551 334.106 54.1829 334.249 54.2411C334.386 54.2827 334.515 54.3511 334.626 54.4423C334.737 54.5335 334.829 54.6458 334.897 54.7726C334.964 54.8995 335.006 55.0384 335.02 55.1815C335.034 55.3246 335.02 55.4691 334.977 55.6065Z'
                fill='#407BFF'
              />
              <path
                opacity='0.5'
                d='M339.077 65.7369L327.039 70.4835L326.31 68.1167L338.348 63.3701L339.077 65.7369Z'
                fill='#407BFF'
              />
              <path
                opacity='0.2'
                d='M272.665 246.471C272.637 254.043 271.905 261.597 270.478 269.034C268.942 275.718 266.556 280.608 263.32 283.703C261.813 285.198 260.023 286.378 258.054 287.175C256.086 287.972 253.978 288.37 251.855 288.345C245.165 288.345 240.059 284.7 236.537 277.408C235.918 276.099 235.391 274.747 234.962 273.364C227.522 279.749 220.595 286.706 214.243 294.171L207.332 302.312C211.051 306.078 215.343 309.234 220.047 311.662C228.151 315.849 238.788 317.947 251.959 317.956C254.275 317.956 256.514 317.891 258.687 317.761C266.655 305.98 273.249 293.327 278.339 280.048L297.665 229.696L272.665 246.471Z'
                fill='black'
              />
              <path
                d='M98.4409 315.121L121.216 337.879L184.987 277.838C163.044 269.502 120.266 293.313 98.4409 315.121Z'
                fill='#263238'
              />
              <path
                d='M177.868 394.486L155.093 371.729L215.18 308.008C223.522 329.933 199.693 372.678 177.868 394.486Z'
                fill='#263238'
              />
              <path
                d='M265.038 251.062L289.883 203.193L241.977 228.018C226.59 235.994 212.382 246.059 199.758 257.928L118.093 334.758L158.216 374.85L235.092 293.248C246.975 280.634 257.052 266.437 265.038 251.062Z'
                fill='#407BFF'
              />
              <path
                opacity='0.6'
                d='M265.038 251.062L289.883 203.193L241.977 228.018C226.59 235.994 212.382 246.059 199.758 257.928L118.093 334.758L158.216 374.85L235.092 293.248C246.975 280.634 257.052 266.437 265.038 251.062Z'
                fill='white'
              />
              <path
                d='M214.022 295.771C223.294 295.771 230.81 288.26 230.81 278.995C230.81 269.73 223.294 262.219 214.022 262.219C204.75 262.219 197.233 269.73 197.233 278.995C197.233 288.26 204.75 295.771 214.022 295.771Z'
                fill='white'
              />
              <path
                d='M214.022 289.776C219.98 289.776 224.811 284.949 224.811 278.995C224.811 273.041 219.98 268.214 214.022 268.214C208.063 268.214 203.233 273.041 203.233 278.995C203.233 284.949 208.063 289.776 214.022 289.776Z'
                fill='#407BFF'
              />
              <path
                opacity='0.3'
                d='M171.777 360.454L132.5 321.207L164.658 290.946L202.061 328.32L171.777 360.454Z'
                fill='#407BFF'
              />
              <path
                d='M107.33 351.117C77.9041 353.51 68.1564 372.314 64.8377 397.334C63.1458 410.079 62.391 422.992 51.6801 430.456C51.0262 430.889 50.5317 431.523 50.2721 432.262C50.0126 433.001 50.0022 433.805 50.2426 434.551C50.483 435.296 50.961 435.943 51.6035 436.392C52.2461 436.841 53.018 437.067 53.8015 437.037C93.2871 435.801 111.703 416.49 114.019 408.427C113.407 412.643 112.316 416.774 110.765 420.742C110.481 421.443 110.426 422.215 110.607 422.948C110.787 423.682 111.195 424.34 111.772 424.829C112.349 425.317 113.065 425.611 113.819 425.669C114.573 425.727 115.326 425.546 115.971 425.151C127.06 418.219 140.959 405.449 141.883 384.733C133.723 373.224 107.33 351.117 107.33 351.117Z'
                fill='#407BFF'
              />
              <path
                opacity='0.2'
                d='M107.33 351.117C77.9041 353.51 68.1564 372.314 64.8377 397.334C63.1458 410.079 62.391 422.992 51.6801 430.456C51.0262 430.889 50.5317 431.523 50.2721 432.262C50.0126 433.001 50.0022 433.805 50.2426 434.551C50.483 435.296 50.961 435.943 51.6035 436.392C52.2461 436.841 53.018 437.067 53.8015 437.037C93.2871 435.801 111.703 416.49 114.019 408.427C113.407 412.643 112.316 416.774 110.765 420.742C110.481 421.443 110.426 422.215 110.607 422.948C110.787 423.682 111.195 424.34 111.772 424.829C112.349 425.317 113.065 425.611 113.819 425.669C114.573 425.727 115.326 425.546 115.971 425.151C127.06 418.219 140.959 405.449 141.883 384.733C133.723 373.224 107.33 351.117 107.33 351.117Z'
                fill='white'
              />
              <path
                d='M146.438 393.589L99.3389 346.514L124.431 341.091L151.878 368.517L146.438 393.589Z'
                fill='#407BFF'
              />
            </g>
            <defs>
              <clipPath id='clip0_635_985'>
                <rect width='500' height='468' fill='white' />
              </clipPath>
            </defs>
          </svg>
          <div className='flex flex-col items-center justify-center'>
            <h3 className=' text-headingMedium font-bold text-dark-400'>
              Trang bạn truy cập không tồn tại
            </h3>
            <p className=' text-fieldInput font-bold text-dark-400'>
              Vui lòng kiểm tra lại đường dẫn và thử các liên kết khác.
            </p>
            <ButtonLink
              href='/'
              text='Trở về trang chủ'
              classes='text-mainBody mx-auto border-none mt-[55px] bg-primary !h-12 leading-12 px-6 w-full text-white-400 !font-bold uppercase rounded-full'
            ></ButtonLink>
          </div>
        </div>
      </section>
    </Main>
  )
}

export default NotFound

export const getStaticProps: GetStaticProps = async (
  context: GetStaticPropsContext
) => {
  const { token, language, messages } = await setContext({
    language: context.locale,
  })

  return {
    props: {
      language,
      messages,
      token: token || null,
    },
  }
}
