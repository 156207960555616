import Link from 'next/link'

interface ButtonLink {
  href: string
  text: string
  classes?: string
  className?: string
  hasImage?: boolean
  style?: React.CSSProperties
  showIcon?: boolean
}

const ButtonLink = ({
  href,
  text,
  classes,
  showIcon,
  className,
  style,
}: ButtonLink) => (
  <Link
    className={`${className ? className : ''} mx-auto block w-max`}
    href={href}
  >
    <div
      className={` flex items-center justify-center gap-x-[4px] px-2 py-2 text-center font-normal  ${
        classes ? classes : ''
      }`}
      style={{
        ...style,
        ...(style?.color ? { color: style?.color } : {}),
      }}
    >
      {text}
      {showIcon && (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
        >
          <path
            d='M11.0006 7.99996C11.0005 8.26515 10.8951 8.51947 10.7076 8.70696L6.70757 12.707C6.51897 12.8891 6.26636 12.9899 6.00417 12.9876C5.74197 12.9854 5.49116 12.8802 5.30575 12.6948C5.12034 12.5094 5.01517 12.2586 5.0129 11.9964C5.01062 11.7342 5.11141 11.4816 5.29357 11.293L8.58657 7.99996L5.29357 4.70696C5.19806 4.61471 5.12188 4.50437 5.06947 4.38236C5.01706 4.26036 4.98947 4.12914 4.98832 3.99636C4.98716 3.86358 5.01247 3.7319 5.06275 3.609C5.11303 3.48611 5.18728 3.37446 5.28117 3.28056C5.37507 3.18667 5.48672 3.11242 5.60961 3.06214C5.73251 3.01186 5.86419 2.98655 5.99697 2.98771C6.12975 2.98886 6.26097 3.01645 6.38297 3.06886C6.50498 3.12127 6.61532 3.19745 6.70757 3.29296L10.7076 7.29296C10.8951 7.48045 11.0005 7.73476 11.0006 7.99996Z'
            fill='#51358D'
          />
        </svg>
      )}
    </div>
  </Link>
)

export default ButtonLink
